<template>
    <div class="m-2 inactive ccss--status-inactive">
        <div class="logo-wrapper text-center">
            <div class="logo-inner">
                <div class="sjef-logo mb-8">
                    <img src="../assets/img/logos/sjef_logo-white.png" alt="">
                </div>
            </div>
            <h2 class="text-2xl font-bold mb-3">{{ $t('inactive.title') }}</h2>
            <h3 class="text-lg">{{ $t('inactive.subtitle') }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'status.inactive',
}
</script>
